
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'jnbank',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public ischeck: boolean = false;
    @Provide() public showLogin: boolean = false;
    @Provide() public acctno: string = '';
    @Provide() public mobile: string = '';

    public async created () {
      this.acctno = getCurrentQuery('acctno')
      this.mobile = getCurrentQuery('mobile')
      if (getMBankAuth()) {
        this.doAuth()
      } else {
        this.showLogin = true
      }
    }

    @Emit()
    public select () {
      this.ischeck = !this.ischeck
    }

    @Emit()
    public async next () {
      if (!this.ischeck) {
        this.$toasted.show('勾选下方同意后，才可去购物哦~')
        return false
      }
      setMBankAuth()
      this.doAuth()
    }

    @Emit()
    public async doAuth () {
      const data: any = {
        openid: this.acctno,
        grant_type: 'Auth_BankJN',
        RegistSource: 2,
        mobile: this.mobile
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/bankjn',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        if (res.code === 0) {
          // location.href = `https://jxjk1-h5.benlai.com/authorize/ccb${location.search}`
          location.href = `https://jnrcb-h5.benlai.com?jnbank=1&acctno=${this.acctno}`
        } else {
          this.$toasted.show('授权失败')
          setTimeout(() => {
            location.replace('/jnbank')
          }, 3000)
        }
      } catch (err) {
        console.log('err', JSON.stringify(err))
        this.$toasted.show(err.msg || err.message)
      }
    }
}
